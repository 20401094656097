 <div class="background">



      
  
  

<div style="margin-top: -100px; display:flex; flex-direction:column; align-items: center; justify-content: center;">
    
   <div  style="width:300px">
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1433.39 765.8">
        <title>Ativo 1</title>
        <g id="Camada_2" data-name="Camada 2">
          <g id="Camada_1-2" data-name="Camada 1">
            <g>
              <path d="M100.88,532.05v-4.32a27.89,27.89,0,0,0-1.18-7.67,16.94,16.94,0,0,0-4.91-7.66,31.5,31.5,0,0,0-10.42-6.1q-6.69-2.55-18.08-2.55-10.62,0-17.1,2.55a26.1,26.1,0,0,0-10,6.49,18.53,18.53,0,0,0-4.52,8.06,32.94,32.94,0,0,0-1,7.66V711.69a33,33,0,0,0,1,7.67,17.26,17.26,0,0,0,4.52,7.86,27.34,27.34,0,0,0,10,6.29q6.48,2.57,17.1,2.55,11.4,0,18.08-2.55a31.5,31.5,0,0,0,10.42-6.09,17,17,0,0,0,4.91-7.67,26.73,26.73,0,0,0,1.18-7.27v-4.33h33.41V718a67.4,67.4,0,0,1-1.57,12.58q-1.58,7.88-8.25,15.72t-20,13.57q-13.36,5.68-36.95,5.7t-36.95-5.7q-13.36-5.7-20.25-13.57T1.82,730.36A69.45,69.45,0,0,1,.25,718V521.83a69.67,69.67,0,0,1,1.57-12.38q1.58-8.06,8.45-15.92T30.52,480q13.35-5.7,37-5.7t37,5.7q13.35,5.7,20,13.56t8.25,15.92a69.67,69.67,0,0,1,1.57,12.38v10.22Z" style="fill: rgb(255, 255, 255); stroke: rgb(255, 255, 255); stroke-miterlimit: 10; stroke-width: 0.5px;" class="svg-elem-1"></path>
              <path d="M295.46,714.05V718a68.26,68.26,0,0,1-1.57,12.58q-1.58,7.88-8.26,15.72t-20.05,13.57q-13.36,5.68-36.95,5.7t-36.95-5.7q-13.37-5.7-20-13.57t-8.26-15.92A69.45,69.45,0,0,1,161.81,718V521.83a69.67,69.67,0,0,1,1.57-12.38q1.56-8.06,8.26-15.92t20-13.56q13.37-5.7,36.95-5.7t36.95,5.7q13.37,5.7,20.05,13.56t8.26,15.92a70.63,70.63,0,0,1,1.57,12.38V714.05Zm-33.41-5.9h.39V532.05h-.39v-4.32a28.28,28.28,0,0,0-1.18-7.67,17.71,17.71,0,0,0-4.72-7.66q-3.54-3.54-10.42-6.1t-18.28-2.55q-10.6,0-17.1,2.55a26.18,26.18,0,0,0-10,6.49,18.53,18.53,0,0,0-4.52,8.06,32.89,32.89,0,0,0-1,7.66V711.69a33,33,0,0,0,1,7.67,17.26,17.26,0,0,0,4.52,7.86,27.42,27.42,0,0,0,10,6.29q6.5,2.57,17.1,2.55,11.4,0,18.28-2.55a28.92,28.92,0,0,0,10.42-6.09,17.8,17.8,0,0,0,4.72-7.67,27.12,27.12,0,0,0,1.18-7.27Z" style="fill: rgb(255, 255, 255); stroke: rgb(255, 255, 255); stroke-miterlimit: 10; stroke-width: 0.5px;" class="svg-elem-2"></path>
              <path d="M467.63,762.79H434.22V528.12a28.33,28.33,0,0,0-1.18-7.67,17.77,17.77,0,0,0-4.72-7.66q-3.54-3.54-10.42-6.1t-18.28-2.55q-10.61,0-17.1,2.55a26.3,26.3,0,0,0-10,6.49,18.53,18.53,0,0,0-4.52,8.06,33,33,0,0,0-1,7.66V762.79H334V522.22a69.67,69.67,0,0,1,1.57-12.38q1.56-8.05,8.25-15.92t20.05-13.56q13.36-5.7,36.95-5.7t36.95,5.7q13.37,5.7,20.05,13.56t8.26,15.92a70.63,70.63,0,0,1,1.57,12.38V762.79Z" style="fill: rgb(255, 255, 255); stroke: rgb(255, 255, 255); stroke-miterlimit: 10; stroke-width: 0.5px;" class="svg-elem-3"></path>
              <path d="M639.8,708.15v10.22a46.27,46.27,0,0,1-2.75,15.33,45,45,0,0,1-8.26,14.35A43,43,0,0,1,615,758.67q-8.25,4.13-19.65,4.12H506.15v-285h89.23q11.4,0,19.65,4.13a42.94,42.94,0,0,1,13.76,10.61,45.18,45.18,0,0,1,8.26,14.35,46.45,46.45,0,0,1,2.75,15.33V708.15Zm-33-175.71V528.9a24.34,24.34,0,0,0-6.1-16.7q-6.09-6.87-16.31-6.88H539.56v230h44.81q10.21,0,16.31-6.88a23.81,23.81,0,0,0,6.1-16.32V532.44Z" style="fill: rgb(255, 255, 255); stroke: rgb(255, 255, 255); stroke-miterlimit: 10; stroke-width: 0.5px;" class="svg-elem-4"></path>
              <path d="M812,714.05V718a67.56,67.56,0,0,1-1.58,12.58q-1.57,7.88-8.25,15.72t-20,13.57q-13.37,5.68-37,5.7t-36.95-5.7q-13.37-5.7-20.05-13.57t-8.25-15.92A69.45,69.45,0,0,1,678.32,718V521.83a69.67,69.67,0,0,1,1.57-12.38q1.56-8.06,8.25-15.92T708.19,480q13.37-5.7,36.95-5.7t37,5.7q13.36,5.7,20,13.56t8.25,15.92A69.88,69.88,0,0,1,812,521.83V714.05Zm-33.42-5.9h.4V532.05h-.4v-4.32a27.89,27.89,0,0,0-1.18-7.67,17.59,17.59,0,0,0-4.71-7.66q-3.54-3.54-10.42-6.1T744,503.75q-10.61,0-17.1,2.55a26.18,26.18,0,0,0-10,6.49,18.53,18.53,0,0,0-4.52,8.06,32.89,32.89,0,0,0-1,7.66V711.69a33,33,0,0,0,1,7.67,17.26,17.26,0,0,0,4.52,7.86,27.42,27.42,0,0,0,10,6.29q6.5,2.57,17.1,2.55,11.4,0,18.28-2.55a28.92,28.92,0,0,0,10.42-6.09,17.68,17.68,0,0,0,4.71-7.67,26.73,26.73,0,0,0,1.18-7.27Z" style="fill: rgb(255, 255, 255); stroke: rgb(255, 255, 255); stroke-miterlimit: 10; stroke-width: 0.5px;" class="svg-elem-5"></path>
              <path d="M1084.77,762.79h-33V528.12a28.33,28.33,0,0,0-1.18-7.67,17.1,17.1,0,0,0-4.92-7.66,31.34,31.34,0,0,0-10.41-6.1q-6.69-2.55-18.09-2.55-11,0-17.49,2.55a24.4,24.4,0,0,0-9.82,6.49,19.42,19.42,0,0,0-4.33,8.06,33,33,0,0,0-1,7.66V762.79H951.12V528.12a28.33,28.33,0,0,0-1.18-7.67,17.1,17.1,0,0,0-4.92-7.66,31.34,31.34,0,0,0-10.41-6.1q-6.69-2.55-18.09-2.55-10.6,0-17.1,2.55a26.3,26.3,0,0,0-10,6.49,18.53,18.53,0,0,0-4.52,8.06,33,33,0,0,0-1,7.66V762.79H850.48V522.22a69.88,69.88,0,0,1,1.58-12.38q1.56-8.05,8.45-15.92t20.24-13.56q13.37-5.7,37-5.7,18.87,0,30.66,3.73t19.27,9.24a72.27,72.27,0,0,1,19.65-9.24q11.79-3.74,30.66-3.73,23.58,0,37,5.7t20,13.56q6.68,7.88,8.25,15.92a69.88,69.88,0,0,1,1.58,12.38V762.79Z" style="fill: rgb(255, 255, 255); stroke: rgb(255, 255, 255); stroke-miterlimit: 10; stroke-width: 0.5px;" class="svg-elem-6"></path>
              <path d="M1244.75,762.79h-77q-11.4,0-19.65-4.12a43.08,43.08,0,0,1-13.76-10.62A44.75,44.75,0,0,1,1126,733.7a46,46,0,0,1-2.76-15.33V522.22a46.21,46.21,0,0,1,2.76-15.33,45,45,0,0,1,8.25-14.35,43.05,43.05,0,0,1,13.76-10.61q8.25-4.12,19.65-4.13h77v27.52h-66q-10.23,0-16.31,6.88a24.29,24.29,0,0,0-6.1,16.7v75.87h71.15v31.06H1156.3v76.25a23.76,23.76,0,0,0,6.1,16.32q6.09,6.89,16.31,6.88h66Z" style="fill: rgb(255, 255, 255); stroke: rgb(255, 255, 255); stroke-miterlimit: 10; stroke-width: 0.5px;" class="svg-elem-7"></path>
              <path d="M1397.66,762.79l-42.45-121.85q-4.34,1.57-7.87,2.94c-2.36.93-4.46,1.64-6.29,2.17l-5.89,2a55.52,55.52,0,0,0-9,3.93,19.85,19.85,0,0,0-6.29,5.7,17.28,17.28,0,0,0-3.15,9.24v95.91h-33.41V522.22a69.67,69.67,0,0,1,1.57-12.38q1.58-8.05,8.26-15.92t20.05-13.56q13.35-5.7,36.95-5.7t37,5.7q13.35,5.7,20,13.56t8.26,15.92a70.63,70.63,0,0,1,1.57,12.38v65.65a45.76,45.76,0,0,1-6.88,24q-6.88,11.4-23.39,18.08L1433,762.79Zm-72.13-144.65q5.29-2,11.2-3.93t11.79-4.13q5.89-2.16,10.62-3.74l4.71-1.57c1.58-.52,3-1,4.33-1.57q9.83-3.54,12.77-9.83a26.56,26.56,0,0,0,3-11V528.12a28,28,0,0,0-1.18-7.67,17,17,0,0,0-4.91-7.66,31.5,31.5,0,0,0-10.42-6.1q-6.69-2.55-18.08-2.55-10.62,0-17.1,2.55a26.22,26.22,0,0,0-10,6.49,18.53,18.53,0,0,0-4.52,8.06,33,33,0,0,0-1,7.66v92.38Q1320.22,620.1,1325.53,618.14Z" style="fill: rgb(255, 255, 255); stroke: rgb(255, 255, 255); stroke-miterlimit: 10; stroke-width: 0.5px;" class="svg-elem-8"></path>
            </g>
            <g>
              <path d="M551.42,96.4V354.17H698.94v-290ZM612,337H583.61V300.08H612Zm-.15-59.18-28.17-.13.17-36.7,28.16.13Zm.27-59.23-28.17-.13.16-36.7,28.17.13Zm.24-53.94-28.17-.13.17-36.7,28.17.13Zm25.41,76.59,28.17.12-.17,36.71-28.17-.13ZM666,337H637.62V300.08H666Zm0-118.16-28.17-.13L638,182l28.16.13Zm.24-53.94-28.16-.13.16-36.7,28.17.13Z" style="fill: rgb(255, 255, 255); stroke: rgb(255, 255, 255); stroke-miterlimit: 10; stroke-width: 0.5px; fill-rule: evenodd;" class="svg-elem-9"></path>
              <path d="M734.35,30.81V354.14H881.87V.31Zm89.3,43.25h28.16v37.76H823.65Zm0,53.94h28.16v37.78H823.65Zm0,53.94h28.16v37.77H823.65Zm0,59.23h28.16v37.77H823.65ZM769.72,74.06h28.17v37.77H769.72Zm0,53.94h28.17v37.78H769.72Zm0,53.94h28.17v37.77H769.72Zm0,59.23h28.17v37.77H769.72ZM798,337H769.62V300.08H798Zm54,0H823.64V300.08H852Z" style="fill: rgb(255, 255, 255); stroke: rgb(255, 255, 255); stroke-miterlimit: 10; stroke-width: 0.5px; fill-rule: evenodd;" class="svg-elem-10"></path>
            </g>
          </g>
        </g>
      </svg>
   </div>
   
   <a href="mailto:contato@condomer.com.br" style="color: white; text-decoration:none; font-family: 'Baloo Tammudu 2', cursive;"> contato@condomer.com.br </a>
   <h2 style="font-family: 'Baloo Tammudu 2', cursive; color: white; text-align:center">Em breve revolucionaremos o mercado condominial</h2>
  </div>   
  <div class="boxes">
    <div class="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
    <div class="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
    <div class="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
    <div class="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>

<!-- dribbble -->
<!-- <a class="dribbble" href="https://dribbble.com/shots/5533600-Loading-boxes" target="_blank"><img src="https://cdn.dribbble.com/assets/dribbble-ball-mark-2bd45f09c2fb58dbbfb44766d5d1d07c5a12972d602ef8b32204d28fa3dda554.svg" alt=""></a> -->
</div> 